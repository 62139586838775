<template>
  <div>
    <ViewTemplateImportFile
      :panel="panel"
      :templateList="templateList"
      container="nixloc-customer-import"
      :typeImport="typeImport"
      :select="options()"
      urlMapping="/api/v1/shared/import-customer-supplier/mapping"
      urlValidate="/api/v1/shared/import-customer-supplier/validate"
      urlImport="/api/v1/shared/import-customer-supplier/import"
      urlTemplate="https://espaco.blob.core.windows.net/nixloc-customer-import/Modelo Importação Cliente.xlsx"
    >
      <div>
        <RadioGroup
          title="O que será importado?"
          field="typeImport"
          :options="[
            { text: 'Clientes', value: 1 },
            { text: 'Endereços', value: 2 },
            { text: 'Contatos', value: 3 },
          ]"
          v-model="typeImport"
        />
      </div>
    </ViewTemplateImportFile>
  </div>
</template>

<script>
import ViewTemplateImportFile from "@nixweb/nixloc-ui/src/component/template/ViewTemplateImportFile";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import { mapMutations } from "vuex";

export default {
  name: "ImportCustomerView",
  components: { ViewTemplateImportFile, RadioGroup },
  data() {
    return {
      panel: {
        module: "CRM",
        title: "Importar",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },

      typeImport: 1,
      selectCustomer: [
        { content: "Código Interno", id: "codInternal" },
        {
          content: "Cliente, Fornecedor ou Ambos ",
          id: "isTypeCustomerSupplier",
        },
        { content: "Tipo", id: "typePerson" },
        { content: "Razão Social/Nome", id: "companyName" },
        { content: "Fantasia/Apelido", id: "tradeName" },
        { content: "CNPJ/CPF", id: "document" },
        { content: "Inscrição Estadual/RG", id: "stateInscription" },
        { content: "Inscrição Municipal", id: "municipalInscription" },
        { content: "Categoria", id: "category" },
        { content: "Origem", id: "origin" },
        { content: "Limite de Crédito", id: "creditLimit" },
        { content: "Limite de Patrimônio", id: "patrimonyLimit" },
        { content: "Telefone", id: "phone" },
        { content: "Site", id: "webSite" },
        { content: "E-mail", id: "email" },
        { content: "Observações", id: "observation" },
      ],
      selectAddress: [
        { content: "Código Interno", id: "codInternal" },
        { content: "Descrição", id: "description" },
        { content: "Cep", id: "zipCode" },
        { content: "Logradouro", id: "street" },
        { content: "Número", id: "number" },
        { content: "Complemento", id: "complement" },
        { content: "Bairro", id: "province" },
        { content: "Cidade", id: "city" },
        { content: "Estado", id: "state" },
      ],
      selectContact: [
        { content: "Código Interno", id: "codInternal" },
        { content: "Nome", id: "name" },
        { content: "Departamento", id: "department" },
        { content: "Data de Nascimento", id: "birthDate" },
        { content: "Celular", id: "mobile" },
        { content: "E-mail", id: "mail" },
      ],
      templateList: {
        urlGetApi: "/api/v1/shared/history-import/get-all",
        urlDeleteAllApi: "/api/v1/shared/history-import/delete",
        showChecks: false,
        headerTable: [
          {
            field: "description",
            title: "Descrição",
            type: "text",
          },
          {
            field: "registeredDate",
            title: "Data",
            type: "dateTime",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "registeredUser",
            title: "Usuário",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "totalImported",
            title: "Total Importado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            type: "button",
            typeButton: "default",
            button: {
              classIcon: "fa-solid fa-trash",
              type: "danger",
              size: "small",
              eventName: "deleteHistoryImport",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    options() {
      if (this.typeImport == 1) return this.selectCustomer;
      if (this.typeImport == 2) return this.selectAddress;
      if (this.typeImport == 3) return this.selectContact;
    },
  },
};
</script>
